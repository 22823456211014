<template>
  <form @submit.prevent="handleSave" method="POST">
    <div class="shadow overflow-hidden sm:rounded-md">
      <div class="px-4 py-5 bg-white sm:p-6">
        <div class="grid grid-cols-6 gap-6">
          
          <div class="col-span-6 sm:col-span-6">
            <label for="title" class="block text-sm font-medium text-gray-700">Titel redirect</label>
            <input type="text" name="title" id="title" autocomplete="given-name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.title">
          </div>

          <div class="col-span-6 sm:col-span-6">
            <label for="source" class="block text-sm font-medium text-gray-700">Origineel / Bron</label>
            <input type="text" name="source" id="source" autocomplete="source" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.source">
          </div>

          <div class="col-span-6 sm:col-span-6">
            <label for="target" class="block text-sm font-medium text-gray-700">Doel, naar</label>
            <input type="text" name="target" id="target" autocomplete="target" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.target">
          </div>

          <div class="col-span-6 sm:col-span-6">
            <label for="httpcode" class="block text-sm font-medium text-gray-700">HTTP code</label>
            <input type="text" name="httpcode" id="httpcode" autocomplete="httpcode" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.httpcode">
          </div>

        </div>

      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
          <svg v-show="loading" class="animate-spin h-4 mr-2 w-4 rounded-full bg-transparent border-2 border-transparent border-opacity-50" style="border-right-color: white; border-top-color: white;" viewBox="0 0 24 24"></svg>
          {{loading ? 'saving': 'save'}}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref } from "vue";

import RedirectService from '../../../services/admin/redirects/service';

export default {
  name: 'RedirectFormComponent',
  props: ['item'],
  setup(props) {
    const loading = ref(true);
    loading.value = false;

    return {
      c: props.item,
      loading
    }
  },
  methods: {
    async handleSave() {
      this.loading = true;
      RedirectService.save(this.item);
      this.loading = false;
    }
  }
}
</script>
