<template>
  <main className="py-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
    <PageHeader title="Wijzig item" />

    <div class="mt-10 sm:mt-0" v-if="!loading">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">General information</h3>
            <p class="mt-1 text-sm text-gray-600">
              Belangrijk om te weten! Controleer de redirect.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <Form :item="data" />
          <button type="button" @click="destroy" v-if="data.id" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
            delete
          </button>
        </div>
      </div>
    </div>

    <div class="hidden sm:block" aria-hidden="true">
      <div class="py-5">
        <div class="border-t border-gray-200"></div>
      </div>
    </div>

  </main>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from 'vue-router'
import RedirectService from '../../../services/admin/redirects/service';
import Form from '../../../components/admin/redirects/Form.vue';

export default {
  name: 'Redirect',
  components: {
    Form
  },
  setup() {
    const data = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const route = useRoute()
    const id = route.params.id;

    function fetchData() {
      loading.value = true;
      // I prefer to use fetch
      // you can use use axios as an alternative
      return RedirectService.get(id).then(res => {
          // a non-200 response code
          if (res.status !== 200) {
            // create error instance with HTTP status text
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }

          return res.data;
        })
        .then(json => {
          // set the response data
          data.value = json;
        })
        .catch(err => {
          error.value = err;
          // In case a custom JSON error response was provided
          if (err.json) {
            return err.json.then(json => {
              // set the JSON response message
              error.value.message = json.message;
            });
          }
        })
        .then(() => {
          loading.value = false;
        });
    }
    onMounted(() => {
      fetchData();
    });

    return {
      data,
      loading,
      error
    };
  },
  methods: {
    async destroy() {
      if(confirm('are you sure?')) {
        this.loading = true;
        await RedirectService.delete(this.data.id);
        this.$router.push(`/redirects/`);
        this.loading = false;
      }
    }
  }
}
</script>