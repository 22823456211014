import axios from "axios";
import authHeader from "../../auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

class RedirectService {
  list(page, limit, query) {
    if (query) {
      return axios.get(API_URL + `admin/route_rules?page=${page}&limit=${limit}&q=${query}`, { headers: authHeader() });
    } else {
      return axios.get(API_URL + `admin/route_rules?page=${page}&limit=${limit}`, { headers: authHeader() });
    }
  }

  get(id) {
    return axios.get(API_URL + `admin/route_rules/${id}`, { headers: authHeader() });
  }

  save(bu) {
    return axios.put(API_URL + `admin/route_rules/${bu.id}`, bu, { headers: authHeader() });
  }

  async create(c) {
    const { data } = await axios.post(API_URL + `admin/route_rules/`, c, { headers: authHeader() });
    return data;
  }

  delete(id) {
    return axios.delete(API_URL + `admin/route_rules/${id}`, { headers: authHeader() });
  }
}

export default new RedirectService();
